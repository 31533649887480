.wrapper {
  @apply w-full;
  @apply px-4 md:px-12;
  @apply bg-white;
  @apply text-black;

  &.light {
    @apply bg-white;
    @apply text-dark;

    .reviewBox {
      @apply bg-primary-fade;
    }
  }

  &.dark {
    @apply bg-black;
    @apply text-white;

    .container {
      @apply bg-dark;
    }

    h4,
    p,
    a {
      @apply text-white;
    }

    .desc {
      @apply text-white;
    }
  }

  &.tertiary {
    @apply bg-primary-fade;
    @apply text-dark;
  }
}

.container {
  @apply max-w-wrap;
  @apply my-0 mx-auto;
  @apply py-12 xl:py-24 px-3 xl:px-32;
  @apply w-full;

  h2 {
    @apply block;
    @apply font-black;
    @apply font-display;
    @apply leading-[0.9];
    @apply text-3xl md:text-4xl;
    @apply text-center;
    @apply -mt-2 mb-6 mx-auto;
    @apply w-full xl:max-w-[60%];
  }

  .desc {
    @apply block;
    @apply text-center;
    @apply leading-normal;
    @apply md:max-w-[40%];
    @apply my-0 mb-10 mx-auto;
  }
}

// Project List
.projectList {
  @apply grid grid-cols-1 md:grid-cols-2;
  @apply gap-16 md:gap-24;
  @apply mt-20;
}
