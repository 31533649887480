.container {
  @apply relative;
  @apply py-10 px-7;
  @apply md:py-4;
  @apply sm:p-20;
  @apply w-full;
  @apply px-7 md:px-12;


  &.light {
    @apply bg-white;
    @apply text-dark;

    .reviewBox {
      @apply bg-primary-fade;
    }
  }

  &.dark {
    @apply bg-dark;
    @apply text-white;

    .container {
      @apply bg-dark;
    }

    h2 {
      @apply text-white;
    }

    .desc {
      @apply text-white;
    }
  }

  &.tertiary {
    @apply bg-primary-fade;
    @apply text-dark;
  }
  
}


.imageWrapper {
  @apply max-w-wrap;
  @apply my-0 mx-auto;
  @apply py-12;
  @apply pt-20;
  @apply pb-20;
  @apply md:pb-8 md:pt-8;
  @apply px-3 xl:px-[6.5rem];
  @apply w-full;
  @apply relative;


  @media (max-width: 1024px) {
    padding: 0 !important;
}

  // max-height: 800px;

  img {
    max-width: 750px;
    // height: 500px;
    @apply w-full;
    @apply my-0 mx-auto;
  }
}


