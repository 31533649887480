.wrapper {
  @apply flex flex-col;
  @apply w-full;
  @apply relative;
}

.cardContent {
  @apply z-[4];
}

.imageWrapper {
  @apply relative;
  @apply w-full h-full;
  @apply aspect-square;
}

.imageWrapper,
.wrapper {
  img {
    @apply w-full h-auto;
    @apply object-cover;
  }
}

.wrapper a {
  @apply no-underline;
}

.wrapper:nth-child(2n) {
  padding: var(--article-padding);
}

.details {
  @apply text-base;
  @apply py-4 px-0;
  @apply leading-4;
  @apply font-normal;

  &.light {
    @apply bg-dark;

    * {
      color: #fff !important;
    }
  }

  &.dark {
    @apply text-white;

    * { 
      @apply text-white;
    }
  }

  &.tertiary {
    @apply bg-white;
    @apply text-dark;
  }

  .title {
    @apply uppercase p-0;
    @apply mt-2 mb-0 mx-0;
    @apply text-white;
    @apply leading-[0];

    a {
      @apply text-white;
      @apply font-bold;
      @apply text-base;
      @apply leading-tight;
    }
  }

  span {
    @apply text-xs;
    @apply m-0 p-0;
    @apply opacity-100;
  }

  .date {
    @apply text-white;
  }

  .time {
    @apply text-white;
    @apply ml-4;
  }
}
