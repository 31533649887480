.container{
    @apply flex;
    @apply gap-x-8;
    @apply text-dark;
    @apply h-[fit-content];
    @apply flex-col;
    @apply md:flex-row;

    span{
        &::after {
            content: '';
            @apply absolute;
            @apply w-full;
            @apply scale-x-0;
            @apply h-[2px];
            @apply left-0 bottom-0;
            @apply bg-black;
            @apply origin-bottom-right;
            transition: transform 0.4s ease;
          }
    }
}

.filterAction{
    @apply relative;
    @apply uppercase;
    @apply font-[600];
    @apply text-[14px];
    @apply cursor-pointer;

    input{
        @apply hidden;
    }
}

.active{
    @apply border-black border-b-2;
    @apply relative;

    &::after {
        @apply scale-x-100;
        @apply origin-bottom-left;
        transition: transform 0.4s ease;
      }
}