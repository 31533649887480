.wrapper {
  @apply w-full;
  @apply px-4 md:px-12;

  h2, h4 {
    @apply text-[22px];
    @apply lg:text-[1.999rem];
    @apply font-display;
    @apply text-center;
    @apply lg:text-left;
    @apply mt-0;
  }

  p {
    @apply text-center;
    @apply lg:text-left;
    &:last-of-type {
      @apply mb-0;
    }
  }
}

.container {
  @apply flex flex-col gap-12 md:flex-row md:gap-28;
  /* @apply py-10 px-[0.8rem];
  @apply md:px-20 py-10; */

  @apply max-w-wrap;
  @apply my-0 mx-auto;
  @apply py-12;
  @apply pt-20;
  @apply pb-20;
  @apply md:pb-32 md:pt-32;
  @apply px-3 xl:px-10;
  @apply w-full;

  &.right {
    @apply flex-row-reverse;
  }
}

.light {
  @apply bg-white;
  @apply text-dark;

  * {
    @apply text-dark;
  }

  .reviewBox {
    @apply bg-primary-fade;
  }
}

.dark {
  @apply bg-dark;
  @apply text-white;

  * {
    @apply text-white;
  }

  .container {
    @apply bg-dark;
  }

  h2 {
    @apply text-white;
  }

  .desc {
    @apply text-white;
  }
}

.tertiary {
  @apply bg-primary-fade;
  @apply text-dark;
}

.singleCol {
  @apply flex;
  @apply gap-28;
  @apply justify-center items-center;
  @apply w-full max-w-[1000px];
  @apply my-0 mx-auto;
  @apply p-4;

  @media only screen and (min-width: 1024px) {
    padding: 4rem 6rem;
  }

  h2 {
    @apply font-display;
    @apply text-6xl;

    i,
    em {
      @apply text-primary;
    }
  }

  h3 {
    @apply leading-tight;
    @apply font-bold;
    @apply text-3xl;
  }
}

.singleCol section {
  @apply flex flex-col;
}

.badgeWrapper {
  @apply relative;
  @apply flex items-center justify-center flex-[1];
  @apply md:justify-center;
}
