.wrapper{
    @apply w-full py-16 px-44;
    @apply flex flex-col items-center justify-center;

    &.light{
      @apply text-white;

      h2{
        @apply text-white;
      }

      .desc{
        @apply text-white;
      }
    }

    &.dark{
      @apply text-dark;

      h2{
        @apply text-dark;
      }

      .desc{
        @apply text-dark;
      }
    }

    h2 {
      @apply block;
      @apply font-display;
      @apply leading-[0.9];
      @apply text-xl sm:text-3xl;
      @apply m-0 mb-2;
      @apply text-dark;
    }

    .desc {
      @apply block;
      @apply leading-normal;
      @apply m-0;
      // @apply w-full;
      @apply text-black;
      @apply mx-auto;
      @apply max-w-[70%];
      @apply mb-8;
    }

    @media (max-width: 758px) {
        @apply px-6;
      }

}