.wrapper {
  @apply relative;
  @apply bg-transparent;

  .container {
    @apply bg-white;
    @apply rounded-xl;
    @apply mt-6;
  }

  .headshot {
    @apply rounded-full;
    @apply overflow-hidden;
    @apply w-14 h-14;
    @apply mx-auto;
    @apply relative;
    @apply -top-6;
    @apply border-3 border-white;

    img {
      @apply object-fill;
    }
  }

  .details {
    @apply text-black;
    @apply text-center;
    @apply flex flex-col;
    @apply gap-3;
    @apply px-6 md:px-12 pb-12;

    .name {
      @apply m-0 p-0;
      @apply leading-[0];
      @apply text-base;
      @apply font-body;
      @apply font-bold;
      @apply text-dark text-opacity-100;
    }

    p {
      @apply m-0 p-0;
      @apply leading-[0];
      @apply text-sm;
      @apply text-dark text-opacity-70;
    }

    .review {
      @apply text-dark;
      p {
        @apply text-sm;
      }
    }

    .company {
      @apply font-light;
    }
  }
}
