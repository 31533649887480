.wrapper {
  padding: 1rem 1rem;

  ul {
    list-style: initial;
    margin: initial;
  }

  @media only screen and (min-width: 1024px) {
    padding: 4rem 6rem;
  }

  h2 {
    font-size: 1.999rem;
    @apply font-display;
  }

  p {
    &:last-of-type {
      @apply mb-0;
    }
  }
}

.light {
  @apply bg-white;
  @apply text-dark;

  * {
    @apply text-dark;
  }

  .reviewBox {
    @apply bg-primary-fade;
  }
}

.dark {
  @apply bg-dark;
  @apply text-white;

  * {
    @apply text-white;
  }

  .container {
    @apply bg-dark;
  }

  h2 {
    @apply text-white;
  }

  .desc {
    @apply text-white;
  }
}

.tertiary {
  @apply bg-primary-fade;
  @apply text-dark;
}

.singleCol {
  @apply flex;
  @apply gap-28;
  @apply justify-center items-center;
  @apply w-full max-w-[1000px];
  @apply my-0 mx-auto;
  @apply pt-4 pb-10 px-7;
  @apply lg:pb-[unset];

  @media only screen and (min-width: 1024px) {
    padding: 8rem 6rem;
  }

  h2 {
    @apply font-display;
    @apply text-6xl;

    i,
    em {
      @apply text-primary;
    }
  }

  h3 {
    @apply leading-tight;
    @apply font-bold;
    @apply text-3xl;
  }
}

.singleCol section {
  @apply flex flex-col;
}

.badgeWrapper {
  @apply relative;
  @apply flex items-center justify-center flex-[1];
  @apply md:justify-center;
}
