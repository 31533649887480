.wrapper {
  @apply py-4 px-7 lg:py-24 lg:px-40;

  &.light {
    @apply bg-white;
    @apply text-dark;

    .reviewBox {
      @apply bg-primary-fade;
    }
  }

  &.dark {
    @apply bg-dark;
    @apply text-white;

    .container {
      @apply bg-dark;
    }

    h2 {
      @apply text-white;
    }

    .desc {
      @apply text-white;
    }
  }

  &.tertiary {
    @apply bg-primary-fade;
    @apply text-dark;
  }

  .container {
    @apply max-w-wrap;
    @apply mx-auto;
    @apply py-12 px-0;
    @apply w-full;
    @apply grid grid-cols-12;

    .imgGrid {
      @apply col-span-full lg:col-start-2 lg:col-end-12;
      @apply grid grid-cols-1 md:grid-cols-2;
      @apply gap-6 lg:gap-12;
    
      > div {
        @apply relative;
        @apply aspect-[4/3];

        img {
          @apply object-cover object-center;
          @apply w-full;
          @apply h-full;
        }
      }
    }
  }
}