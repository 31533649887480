.container{
  @apply relative;
  @apply w-full;
  @apply py-20;
}

.imageWrap {
  @apply relative;
  @apply max-w-wrap;
  @apply my-0 mx-auto;
  @apply py-12;
  @apply pt-20;
  @apply pb-20;
  @apply md:pb-[13rem] md:pt-32;
  /* @apply px-3 xl:px-10; */
  @apply w-full;

  &.noGap{
    @apply py-0;
  }

  > div {
    @apply w-full h-full;
  }

  img {
    @apply w-full;
  }
}
