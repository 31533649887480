.wrapper {
  @apply w-full max-w-[1000px];
  @apply mx-auto my-28;

  @apply flex flex-col;
  @apply justify-center;
  @apply gap-[50px];

  h2 {
    @apply text-dark;
    @apply font-display;
    @apply m-0 p-0;
    @apply w-full;
    @apply text-center;
  }

  .showreel {
    @apply mx-auto;
    @apply w-full;
    @apply pointer-events-none;

    .image {
      @apply w-full h-[675px];
      @apply relative;

      img {
        @apply w-full h-full;
        @apply object-cover;
        @apply object-center;
      }
    }
  }
}
