.wrapper {
    @apply flex flex-col;
    @apply gap-3;
    @apply p-0 m-0;
  
    * {
      @apply text-dark;
    }
  
    .image {
      @apply relative;
      @apply w-full;
    }
  
    .meta {
      @apply flex;
      @apply justify-between;
      @apply mt-2;
  
      span {
        @apply text-sm;
        @apply text-dark text-opacity-50;
      }
    }
  
    .title {
      @apply m-0 p-0;
      @apply font-bold;
      @apply text-dark;
  
      @apply text-xl;
    }
    
    .readMore {
      @apply text-dark text-opacity-50 hover:text-opacity-100;
      @apply decoration-1 underline-offset-3;
      @apply decoration-dark;
      @apply self-start;
      text-decoration: underline;
    }
  }
  