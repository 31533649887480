.container {
  @apply py-16 px-0;
  @apply relative;


  &.light {
    @apply bg-white;
    @apply text-dark;

    .reviewBox {
      @apply bg-primary-fade;
    }
  }

  &.dark {
    @apply bg-black;
    @apply text-white;

    .container {
      @apply bg-dark;
    }

    h2 {
      @apply text-white;
    }

    .desc {
      @apply text-white;
    }
  }

  &.tertiary {
    @apply bg-primary-fade;
    @apply text-dark;
  }

  h2 {
    @apply block;
    @apply font-black;
    @apply font-display;
    @apply leading-[0.9];
    @apply text-3xl md:text-4xl;
    @apply text-center;
    @apply -mt-2 mb-6 mx-auto;
    @apply w-full xl:max-w-[60%];
  }

  .description {
    @apply block;
    @apply text-center;
    @apply leading-normal;
    @apply md:max-w-[40%];
    @apply my-0 mb-10 mx-auto;
  }

  @media only screen and (max-width: 1014px) {
    @apply py-16 px-16;
  }

  @media only screen and (max-width: 500px) {
    @apply py-16 px-4;
  }
}

.calendar {
  @apply h-[660px];
  @apply relative;
  overflow: hidden;

  iframne{
    display: block;
  border: none;         
  height: 100vh;  
  overflow: hidden;

  }

  > div{
    overflow: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
    
    -ms-overflow-style: none;
    scrollbar-width: none; 
  }


  @media only screen and (max-width: 1014px) {
    @apply h-[760px];
  }

  @media only screen and (max-width: 500px) {
    @apply h-[650px];
  }
}
