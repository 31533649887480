.wrapper {
  @apply w-full;
  @apply px-4 md:px-12;
  @apply bg-black;
}

.container {
  @apply max-w-wrap;
  @apply my-0 mx-auto;
  @apply py-12 xl:py-24 px-3 xl:px-10;
  @apply w-full;

  h2 {
    @apply block;
    @apply font-black;
    @apply font-display;
    @apply leading-[0.9];
    @apply text-3xl md:text-4xl;
    @apply text-center;
    @apply -mt-2 mb-6 mx-auto;
    @apply w-full xl:max-w-[60%];
  }

  .desc {
    @apply block;
    @apply text-center;
    @apply leading-normal;
    @apply md:max-w-[40%];
    @apply my-0 mb-10 mx-auto;
  }
}

.dragPane {
  @apply cursor-default;
  transition: cursor 0.5s ease;
}

.dragPane h4 {
  letter-spacing: 0.2px;
  font-size: 19px;
  margin-bottom: 17px;
}

.dragPane:hover {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='none' viewBox='0 0 111 111'%3E%3Ccircle cx='55.5' cy='55.5' r='55.5' fill='%23FAFF00'/%3E%3Cpath fill='%23000' d='M39.1 63v-1.3h1.3V63H43V45h-2.6v6.4h-1.3v-1.2H34v1.2h-1.3v1.3h-1.3v7.7h1.3v1.3H34V63h5Zm-3.9-2.6v-1.2H34V54h1.3v-1.3H39V54h1.3v5.2H39v1.2h-3.9ZM48.1 63v-9h1.3v-1.3h3.2v-2.5h-3.2v1.2H48v-1.2h-2.6V63H48Zm13.5 0v-1.3H63V63h2.6V52.7h-1.3v-1.3H63v-1.2h-6.4v1.2h-1.3v1.3H54V54h3.9v-1.3h3.8V54H63v1.3h-5.1v1.3h-2.6v1.3H54v2.5h1.3v1.3h1.3V63h5.1Zm-4.5-2.6V58h4.5v-1.3H63v2.6h-1.3v1.2h-4.5Zm20 7.7v-1.3h1.2v-1.2h1.3V50.2H77v1.2h-1.3v-1.2h-5.1v1.2h-1.3v1.3H68v7.7h1.3v1.3h1.3V63h5.1v-1.3H77v2.6h-1.3v1.3H72v-1.3h-4v1.3h1.3v1.2h1.3v1.3H77Zm-5.2-7.7v-1.2h-1.3V54h1.3v-1.3h3.8V54H77v5.2h-1.3v1.2H72Z'/%3E%3C/svg%3E"),
    auto;
}
