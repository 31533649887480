.wrapper {
  @apply w-full;
  @apply py-10; 
  @apply px-7;
  @apply lg:py-20;
  @apply m-0;

  &.light {
    @apply bg-white;
    @apply text-dark;

    .reviewBox {
      @apply bg-primary-fade;
    }
  }

  &.dark {
    @apply bg-dark;
    @apply text-white;

    .container {
      @apply bg-dark;
    }

    h2 {
      @apply text-white;
    }

    .desc {
      @apply text-white;
    }
  }

  &.tertiary {
    @apply bg-primary-fade;
    @apply text-dark;
  }

  .container {
    @apply max-w-wrap;
    @apply mx-auto;
    @apply py-12 px-0;
    @apply w-full;
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12;
    @apply gap-6 lg:gap-4;
  }

  // ===

  h4 {
    @apply m-0 p-0;
    @apply text-[24px];
    @apply col-span-full;
    @apply lg:col-start-2 lg:col-end-12;
    @apply mb-4;
  }

  // ===

  .inner {
    @apply col-span-full;
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12;
    @apply gap-4;
  
    div {
      &:first-of-type {
        @apply col-start-1 col-end-1;
        @apply md:col-start-1 md:col-end-2;
        @apply lg:col-start-2 lg:col-end-7;
        @apply lg:pr-12;
      }
  
      &:last-of-type {
        @apply col-start-1 col-end-1;
        @apply md:col-start-2 md:col-end-3;
        @apply lg:col-start-7 lg:col-end-12;
        @apply lg:pl-12;
      }
    }
  }
}


