.wrapper {
    padding-top: var(--large-padding);
    padding-bottom: var(--large-padding);
    @apply flex flex-col;
    @apply items-center;
  
    &.right{
      @apply flex-row-reverse;
    }
  
    &.left{
      @apply flex-row
    }
  
    padding: 1rem 1rem;
  
    @media only screen and (min-width: 1024px) {
      padding: 4rem 6rem;
    }
    
    h3{
        @apply max-w-[unset];
        @apply font-display;
        @apply text-center;
        @apply text-[1.8rem];
        @apply lg:max-w-[50%];
        @apply lg:text-[2.4rem];

    }

    .description{
        @apply mb-10;
    }

    &.light {
      @apply bg-white;
      @apply text-dark;
    }
  
    &.dark {
      @apply bg-dark;
      @apply text-white;
  
      .container {
        @apply bg-dark;
      }
  
      h2 {
        @apply text-white;
      }
  
      .desc {
        @apply text-white;
      }
    }
  
    &.tertiary {
      @apply bg-primary-fade;
      @apply text-dark;
    }
  }

  .faqArea{
      @apply max-w-[unset];
      @apply lg:max-w-[70%];
  }


  .faqWrapper {
    @apply border-0 border-b-2 border-black border-opacity-20;
  
    &:first-of-type {
      @apply border-t-0;
    }
  
    &.active {
      @apply mb-8;
    }
  }
  
  .tab {
    @apply cursor-pointer;
    @apply flex flex-row;
    @apply font-bold;
    @apply font-display;
    @apply gap-5;
    @apply items-center;
    @apply leading-tight;
    @apply py-8;
    @apply relative;
    @apply uppercase;
    @apply text-base;
    @apply lg:text-[22px];
  
    .arrow{
      @apply block;
      @apply w-[65px];
      @apply ml-auto;
      @apply pr-4;
    }
  }
  
  .control {
    font-size: 20px;
  }
  
  .content {
    @apply pb-4;
    @apply pr-0;
    @apply lg:px-[2.1rem];
  
    ul {
      list-style: initial;
      margin: initial;
    }
  }
  
  .content_wrapper {
    @apply overflow-hidden h-0;
    transition: height ease 0.2s;
  }
  