.wrapper {
  @apply flex flex-col;
  @apply gap-9;
  @apply max-w-[550px];

  &:nth-of-type(even) {
    @apply pt-0 xl:pt-32;
  }

  .imageWrapper {
    @apply relative;
    // @apply h-96 xl:h-[600px];
    @apply aspect-[9/11];

    img {
      min-width: 100%;
      max-width: 100%;
      min-height: 100%;
      max-height: 100%;
      @apply object-cover;
    }
  }

  .detailsWrapper {
    @apply w-full;
    @apply flex flex-col;
    @apply gap-3 xl:gap-5;

    h3 {
      @apply text-xl xl:text-2xl;
      @apply text-dark;
      @apply m-0 p-0;
      @apply leading-none;
      @apply xl:font-display;
      @apply font-bold;
    }

    h4 {
      @apply text-xl xl:text-2xl;
      @apply text-dark;
      @apply m-0 p-0;
      @apply leading-none;
      @apply xl:font-display;
      @apply font-bold;
    }

    p {
      @apply text-dark;
      @apply m-0 p-0;
      @apply leading-none;
    }

    a {
      @apply text-dark;
      @apply uppercase;
      @apply m-0 p-0;
      @apply leading-none;
      @apply underline;
      @apply font-semibold;
    }
  }
}
