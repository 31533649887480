.wrapper {
  @apply w-full;
  @apply px-4 md:px-12;

  &.light {
    @apply bg-white;
    @apply text-dark;

    .reviewBox {
      @apply bg-primary-fade;
    }
  }

  &.dark {
    @apply bg-black;
    @apply text-white;

    .container {
      @apply bg-black;
    }

    .title {
      @apply text-primary-fade;
    }

    .desc {
      @apply text-white;
    }
  }

  &.tertiary {
    @apply bg-primary-fade;
    @apply text-dark;
  }

  .container {
    @apply max-w-wrap;
    @apply my-0 mx-auto;
    @apply py-16 md:py-24 xl:py-32 px-0;
    @apply w-full;
    @apply flex flex-col;
    @apply justify-center items-center;

    .title {
      @apply block;
      @apply uppercase;
      @apply font-black;
      @apply font-display;
      @apply leading-[0.9];
      @apply text-3xl md:text-4xl lg:text-5xl xl:text-6xl;
      @apply text-center;
      @apply -mt-2 mb-16 mx-auto;
      @apply w-full max-w-wrap;
    }
  }
}


.extraLink{
  a {
    @apply m-0 p-0;
    @apply text-sm;
    @apply relative;
    transition: all 0.5s ease;
    padding-bottom: 4px;

    &::after {
      content: '';
      @apply absolute;
      @apply w-full;
      @apply scale-x-0;
      @apply h-[2px];
      @apply left-0 bottom-0;
      @apply bg-black;
      @apply origin-bottom-right;
      transition: transform 0.4s ease;
    }



    &:hover {
      &::after {
        @apply scale-x-100;
        @apply origin-bottom-left;
      }
    }
  }

  &.lightUnder{
    a{
      &::after{
        @apply bg-dark;
      }
    }
  }

  &.darkUnder{
    a{
      &::after{
        @apply bg-white;
      }
    }
  }
}