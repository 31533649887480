.wrapper {
  @apply w-full;
  @apply flex flex-row;
  @apply justify-between items-center;
  @apply gap-40;
}

.review {
  @apply w-full lg:max-w-[450px];
  @apply text-center;
  @apply px-2 xl:px-4;
  @apply flex flex-col;
  @apply justify-between;
  @apply gap-2;

  p {
    @apply text-sm;
    @apply my-0 mx-auto p-0;
    @apply w-[90%];
  }
}

.desktop {
  @apply w-full;
  @apply hidden flex-row;
  @apply justify-between items-center;
  @apply lg:flex;

  .review {
    flex: 1;
  }
}

.mobile {
  @apply contents;
  @apply lg:hidden;
}
