.wrapper {
  @apply w-full;
  @apply py-20;

  &.light {
    @apply bg-white;
    @apply text-dark;
  }

  &.dark {
    @apply bg-black;
    @apply text-white;

    .container {
      @apply bg-black;
    }

    h2 {
      @apply text-white;
    }

    .desc {
      @apply text-white;
    }
  }

  &.tertiary {
    @apply bg-primary-fade;
    @apply text-dark;
  }
}

.container {
  @apply max-w-wrap;
  @apply mx-auto;
  @apply py-12 px-6 lg:px-0;
  @apply w-full;

  h2 {
    @apply block;
    @apply font-black;
    @apply font-display;
    @apply leading-[0.9];
    @apply text-xl md:text-4xl;
    @apply text-center;
    @apply m-0 mb-6 mx-auto;
  }

  .desc {
    @apply block;
    @apply text-center;
    @apply leading-normal;
    @apply md:max-w-[40%];
    @apply my-0 mb-10 mx-auto;
    @apply mt-10;
  }

  .teamGrid {
    @apply grid grid-cols-1 sm:grid-cols-2;
    @apply gap-12 lg:gap-44;
    @apply w-full max-w-4xl;
    @apply p-0 mt-32 mb-0 mx-auto;

    & > div {
      &:nth-of-type(1) {
        @apply lg:mt-0;
      }

      &:nth-of-type(2) {
        @apply lg:mt-44;
      }

      &:nth-of-type(3) {
        @apply lg:-mt-20;
      }

      &:nth-of-type(4) {
        @apply lg:mt-12;
      }
    }
  }
}



