.wrapper {
  @apply border-2 border-black;
  @apply rounded-3xl;
  @apply py-12 px-4 sm:px-8;
  @apply flex flex-col;
  @apply justify-center;

  * {
    @apply text-dark;
  }

  &.article{
    @apply h-full;
    
    form{
      label{
        @apply flex;
        @apply pl-5;
        span{
          @apply block;
          @apply text-[13px];
        }
      }
    }
  }
}