.container {
  @apply block;
  @apply mb-24;
}

.paginationInner {
  @apply flex w-full justify-center items-center gap-14 mt-24;
}

.paginationInner li {
  @apply flex;
  @apply items-center justify-center;
  @apply font-display;
  @apply font-black;
  font-size: 1.8rem;
}

.filterOption {
  @apply p-2;
  @apply rounded-xl;
  @apply border-4 border-transparent;
  @apply font-bold;
  @apply text-dark;
  @apply cursor-pointer;
  @apply transition-all duration-300 ease-in-out;

  label {
    @apply cursor-pointer;
  }

  span {
    @apply pointer-events-none;
  }

  &:nth-of-type(odd) {
    @apply bg-tertiary;
  }

  &:nth-of-type(even) {
    @apply bg-primary;
  }
}

.selectedFilterOption {
  @apply border-white;
  @apply transition-all duration-300 ease-in-out;
}

.filterCount {
  font-size: 12px;
  @apply relative ml-1;
  bottom: 8px;
}

.sortbyContainer {
  @apply relative;
}

.sortbyContainer::before {
  content: 'Filter by';
  @apply absolute text-center w-full;
  top: -35px;
}

.sortbyFilter {
  @apply p-2 text-center relative;
  border-radius: 10px;
  background: transparent;
  border: 2px solid #fff;
  min-width: 160px;
}

.sortbyFilter {
  -moz-appearance: none;
  -webkit-appearance: none;
}

.sortbyFilter::-ms-expand {
  @apply hidden;
}

.searchbarFilter input {
  background: transparent;
  border-radius: 10px;
  border: 2px solid #fff;
  @apply p-2;
}

.searchbarFilter button {
  @apply hidden;
}
