.container{
    @apply relative;
    @apply flex;
    @apply max-w-full;
    @apply mt-8;
    @apply md:mt-0;
}

.wrapper{
    @apply flex;
    @apply w-[600px];
}

.searcher{
    @apply w-full;
    @apply rounded-full;
    @apply border-black border border-solid;
    @apply pl-14 pt-2 pb-2 pr-2;
    @apply text-dark;
    @apply relative;

    &:focus{
        outline: 0;
    }
}

.bar{
    @apply flex;
    @apply w-full;
    @apply relative;

    &::after{
        content: '';
        @apply absolute;
        @apply w-[20px];
        @apply h-[20px];
        @apply top-[50%];
        @apply translate-y-[-50%];
        left: 20px;
        background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23clip0_1890_1172)'%3e%3cpath d='M11.0444 18.0887C14.9349 18.0887 18.0887 14.9349 18.0887 11.0444C18.0887 7.15387 14.9349 4 11.0444 4C7.15387 4 4 7.15387 4 11.0444C4 14.9349 7.15387 18.0887 11.0444 18.0887Z' stroke='black' stroke-miterlimit='10'/%3e%3cpath d='M20.9993 20.9998L16.0215 16.022' stroke='black' stroke-miterlimit='10'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_1890_1172'%3e%3crect width='24' height='24' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
    }

}