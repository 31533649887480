.wrapper {
  @apply w-screen h-screen max-h-[1040px] min-h-[780px];
  @apply relative;
  @apply overflow-hidden;
  @apply p-4 sm:p-6 xl:p-20;
  @apply bg-primary-fade;
  @apply flex items-center;

  .logo{
    @apply relative;
    @apply mb-4;
  }

  &.light {
    @apply bg-white;
    @apply text-dark;

    .reviewBox {
      @apply bg-primary-fade;
    }
  }

  &.dark {
    @apply bg-dark;
    @apply text-white;

    h1,h2 {
      @apply text-white;
    }

    h1,h2 span {
      @apply text-white;
    }

    .desc {
      @apply text-white;
    }

    p{
      @apply text-white;
    }
  }

  &.tertiary {
    @apply bg-primary-fade;
    @apply text-dark;
  }

  &.shallow{
    @apply h-[unset];
    @apply max-h-[unset] min-h-[unset];
    @apply p-4 sm:p-6 xl:p-14;

    .container{
      @apply mt-24;
      @apply items-start;
      
      /* @apply max-w-wrap; */

      .content{
        @apply text-left;
        @apply items-start justify-start;
        @apply px-[0.65rem];
        @apply xl:px-[1.9rem];

        h1{
          @apply text-[2.5rem];
        }

        h2{
          @apply text-left;
        }

        p{
          @apply max-w-[100%];
          @apply md:max-w-[50%];
          @apply text-left;
        }

      }
    }
 
  }

  .container {
    @apply max-w-wrap;
    @apply my-0 mx-auto p-0;
    @apply w-full h-full;
    @apply flex flex-col;
    @apply justify-between items-center;

    * {
      @apply text-dark;
      @apply text-center;
    }

    .content {
      @apply flex flex-col;
      @apply items-center justify-center;
      @apply gap-4;

      h1,
      h2 {
        @apply font-display;
        @apply m-0 p-0;
      }

      h1 {
        @apply leading-[0.9];
        @apply text-4xl md:text-5xl xl:text-7xl;
        @apply uppercase;
        @apply font-black;
      }

      h2 {
        @apply text-2xl xl:text-4xl;

        span {
          &:first-of-type {
            @apply xl:hidden;
            @apply uppercase;
            @apply font-body;
            @apply font-bold;
            @apply leading-[0px];
          }
          &:last-of-type {
            @apply hidden xl:inline-block;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke: 1px;
          }
        }
      }

      p {
        @apply m-0 p-0;
      }

      a {
        @apply uppercase;
        @apply underline;
      }
    }
  }
}

// BagMan
.bagMan {
  @apply w-56;
  @apply my-1;
  @apply overflow-hidden;

  @apply md:w-[28%];
  // @apply md:w-60;
  @apply md:absolute;
  @apply md:-right-8 md:bottom-[20%];
  // @apply md:-translate-y-1/2;
  @apply md:my-3;

  @apply xl:w-[28%];
}
