.wrapper {
  @apply w-full;
  @apply px-4 md:px-12;
  @apply bg-black;
}

.container {
  @apply max-w-wrap;
  @apply my-0 mx-auto;
  @apply py-12;
  @apply pt-20;
  @apply pb-20;
  @apply md:pb-[13rem] md:pt-32;
  @apply px-3 xl:px-10;
  @apply w-full;

  h2 {
    @apply block;
    @apply font-black;
    @apply font-display;
    @apply leading-[0.9];
    @apply text-3xl md:text-4xl;
    @apply text-center;
    @apply -mt-2 mb-6 mx-auto;
  }

  .desc {
    @apply block;
    @apply text-center;
    @apply leading-normal;
    @apply md:max-w-[40%];
    @apply my-0 mb-10 mx-auto;
  }
}

.iconGrid {
  @apply grid grid-cols-2	gap-20;
  @apply md:grid-cols-3;
  @apply lg:grid-cols-5;
  @apply max-w-[80%];
  @apply mx-auto mt-24 mb-0;
}

.iconGrid__item {
  @apply flex;
  @apply relative;
  @apply justify-center items-center;
  // @apply mt-7;
  width: 100%;
  height: 50px;

  &:last-of-type {
    @apply hidden;
    @apply lg:flex;

    @media only screen and (max-width: 768px) {
      @apply flex;
    }
  }

  img {
    object-fit: contain;
    max-width: 160px;
    @apply m-auto;
  }
}
