.container{
    @apply relative;
    @apply mt-32;
    @apply flex justify-center;
    @apply gap-8;

    a{
        @apply text-dark;
    }
}

.navButton{
    @apply bg-dark;
    @apply text-white;
    @apply rounded-full;
    @apply p-3;
    @apply flex-[1];
    @apply max-w-[200px];
    @apply text-center;
    @apply cursor-pointer;
}