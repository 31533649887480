.wrapper {
  @apply flex flex-col sm:flex-row;
  @apply justify-start items-center;

    .imageWrapper {
      @apply w-full sm:w-auto;
      @apply sm:mr-6;
      @apply relative;
      

    .image {
      @apply w-full h-[140px];
      @apply sm:w-[189px] sm:h-[155px];
      @apply relative;
      @apply m-0 p-0;
      @apply overflow-hidden;
      @apply rounded-xl sm:rounded-3xl;
    }

  
      img {
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
        @apply object-cover;
      }
    }
  
    .detailsWrapper {
      @apply w-full;
      @apply flex flex-col;
      @apply gap-3 xl:gap-5;
  
      h3 {
        @apply text-[22px] sm:text-[28px];
        @apply text-dark;
        @apply m-0 p-0;
        @apply leading-none;
        @apply font-[900];
      }
  
      h4 {
        @apply text-xl xl:text-2xl;
        @apply text-dark;
        @apply m-0 p-0;
        @apply leading-none;
        @apply xl:font-display;
        @apply font-bold;
      }
  
      p {
        @apply text-[14px] leading-normal;
        @apply text-dark;
        @apply m-0;
      }
  
      a {
        @apply text-dark;
        @apply uppercase;
        @apply m-0 p-0;
        @apply leading-none;
        @apply underline;
        @apply font-semibold;
      }
    }
  }
  