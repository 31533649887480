.wrapper {
  padding-top: var(--large-padding);
  padding-bottom: var(--large-padding);
 
  @apply w-full;
  @apply px-4 md:px-12;

  padding: 1rem 1rem;

  @media only screen and (min-width: 1024px) {
    padding: 4rem 6rem;
  }

  h4{
    @apply block;
    @apply font-black;
    @apply font-display;
    @apply leading-[0.9];
    @apply text-2xl md:text-3xl;
    @apply text-left;
    @apply -mt-2 mb-6 mx-auto;
  }

  &.light {
    @apply bg-white;
    @apply text-dark;

    .reviewBox {
      @apply bg-primary-fade;
    }
  }

  &.dark {
    @apply bg-dark;
    @apply text-white;

    .container {
      @apply bg-dark;
    }

    h2 {
      @apply text-white;
    }

    .desc,.text {
      @apply text-white;
    }
  }

  &.tertiary {
    @apply bg-primary-fade;
    @apply text-dark;
  }
}

.image {
  @apply w-full;
  @apply lg:w-2/4;
  @apply relative;
  /* @apply aspect-square; */
  @apply h-auto;

  img {
    @apply object-cover;
  }
}

.text {
  @apply w-full;
  @apply lg:w-2/4;
  @apply text-dark;
}

.text p:last-of-type {
  @apply mb-0;
}

.container{
  @apply max-w-wrap;
  @apply my-0 mx-auto;
  @apply py-12;
  @apply pt-20;
  @apply pb-20;
  @apply md:pb-28 md:pt-28;
  @apply px-3 xl:px-10;
  @apply w-full;
  @apply flex;
  gap: var(--large-padding);
  @apply flex-col;
  @apply lg:flex-row;
  @apply items-center;
  // @apply flex-col;

  &.right{
    @apply flex-col-reverse;
    @apply xl:flex-row-reverse;
  }

  &.left{
    @apply flex-col;
    @apply xl:flex-row
  }
  
}