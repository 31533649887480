.wrapper {
  @apply w-full;
  @apply text-white;
  @apply px-0;

  &.light {
    @apply bg-white;
    @apply text-dark;

    .reviewBox {
      @apply bg-primary-fade;
    }
  }

  &.dark {
    @apply bg-black;
    @apply text-white;

    .container {
      @apply bg-dark;
    }

    h2 {
      @apply text-white;
    }

    .desc {
      @apply text-white;
    }
  }

  &.tertiary {
    @apply bg-primary-fade;
    @apply text-dark;
  }
}

.container {
  @apply py-24 px-0;
  @apply rounded-xl;
  @apply w-full;
  //   @apply flex flex-col;
  //   @apply justify-center;

  .body {
    @apply max-w-[70ch];
    @apply mx-auto mb-4 sm:mb-20;
    @apply text-center;
  }

  h2 {
    @apply block;
    @apply font-display;
    @apply leading-[0.9];
    @apply text-xl sm:text-3xl;
    @apply m-0 mb-2;
    @apply text-dark;
  }

  .desc {
    @apply block;
    @apply leading-normal;
    @apply m-0;
    @apply w-full;
    @apply text-black;
  }

  .swiperContainer {
    @apply px-6 xl:px-6;

    .slide {
      @apply flex;
      @apply self-center;
    }
  }
}
