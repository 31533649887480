.wrapper{
    @apply flex flex-wrap;
    @apply justify-between;
    @apply items-start;
    @apply relative;
    @apply py-8;
    @apply mb-24;
    @apply flex-col;
    @apply md:flex-row md:items-center;
    @apply gap-x-10 gap-y-4;

    &::after{
        content: '';
        @apply absolute;
        @apply bottom-0 left-0 right-0;
        @apply block;
        @apply w-full;
        @apply h-[2px];
        @apply bg-[rgba(0,0,0,0.1)];

    }
}