.outerWrap {
  padding: 1rem 1rem;

  @media only screen and (min-width: 1024px) {
    padding: 4rem 6rem;
  }

  &.light {
    @apply text-white;
  }

  &.dark {
    @apply text-dark;

    h2 {
      @apply text-white;
    }

    .desc {
      @apply text-white;
    }
  }

  &.tertiary {
    @apply bg-primary-fade;
    @apply text-dark;
  }
}

.wrapper {
  // padding-top: var(--large-padding);
  padding-bottom: var(--large-padding);
  gap: 20px;
  @apply flex flex-col;

  @media only screen and (min-width: 1024px) {
    @apply flex-row;
    gap: var(--large-padding);
  }
}

.wrapper div {
  @apply w-full;

  @media only screen and (min-width: 1024px) {
    @apply w-2/4;
  }
}

.statBox {
  @apply flex;
  @apply justify-center items-center;
  @apply gap-6;
  @apply bg-white;

  .stat {
    @apply flex;
    @apply items-center;
    @apply gap-6;
    @apply p-8;
  }

  .statRing {
    @apply flex;
    @apply justify-center items-center;
    @apply w-[50px];
    @apply h-[50px];
    @apply rounded-full;
    @apply border-2 border-white;
    background: transparent;
  }
}

.statInfo {
  @apply flex flex-col;
}
