.wrapper {
  @apply flex flex-col;
  @apply pt-20;

  &.across{
    @apply flex-col;
    @apply md:flex-row;

    section,
    > div{
      flex: 1
    }

    >div{
      height: fit-content;
    }

    section{
      height: fit-content;
    }

    .description{
      max-width: 80%;
      @apply text-left;
    }


    .marqueeContent {
      @apply flex-col;
    }

  }
  

  h2 {
    @apply block;
    @apply font-black;
    @apply font-display;
    @apply leading-[0.9];
    @apply text-3xl md:text-4xl;
    @apply text-center;
    @apply -mt-2 mb-6 mx-auto;
    @apply w-full xl:max-w-[60%];
  }

  .description {
    @apply block;
    @apply text-center;
    @apply leading-normal;
    @apply md:max-w-[40%];
    @apply my-0 mb-10 mx-auto;
    @apply mb-24;
  }

  &.light {
    @apply bg-white;
    @apply text-dark;
  }

  &.dark {
    @apply bg-dark;
    @apply text-white;

    .container {
      @apply bg-dark;
    }

    h2 {
      @apply text-white;
    }

    .desc {
      @apply text-white;
    }
  }

  &.tertiary {
    @apply bg-primary-fade;
    @apply text-dark;
  }
}

.container {
  @apply flex items-center justify-center;
  overflow: hidden;
  /* height: 290vh; */
}

.imageContainer {
  --gap: 80px;
  @apply relative;
  @apply w-full;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);

  &.horizontalLarge{
      @apply flex flex-col;
      @apply gap-[80px];
  }

  &.vHeight{
    max-height: 850px;
  }

  // [data-amount='3'] {
  //   @apply grid-cols-2;
  //   @apply xl:grid-cols-3;
  // }
  // [data-amount='4'] {
  //   @apply grid-cols-4;
  // }
  // [data-amount='5'] {
  //   @apply grid-cols-2;
  //   @apply xl:grid-cols-5;
  // }

  ul {
    // @apply list-none;
    // @apply gap-[10px];
    // margin: 0 auto 20px;
    // // margin-left: -10%;
    // @apply p-0;
    // @apply relative;

    // &.horizontal{
    //   // @apply grid;
    //   overflow: hidden;

    //   // margin-left: -60%;


    //   li{
    //     img{
    //       @apply object-cover;
    //     }
    //   }
    // }

    &.horizontalLarge{
      @apply flex;
      @apply gap-[80px];

      li{
        @apply aspect-[16/12];
        flex: 1;
        @apply pb-0;
        @apply min-h-[640px];

        img{
          @apply object-cover;
        }
      }

      // reduce the margin depending on the blocks index

      // &.setDefault{
      //   margin-left: -110%;
      
      //   &:last-of-type{
      //     margin-left: 50%;
      //   }
      // }

      // &.setShort{
      //   margin-left: -60%;
      
      //   &:last-of-type{
      //     margin-left: 10%;
      //   }
      // }

    }
  }
}

// .ration {
//   // border-radius: 20px;
//   @apply h-0;
//   padding-bottom: 100%;
//   @apply relative;
//   /* @apply w-full; */
//   overflow: hidden;
//   @apply aspect-square;

//   img {
//     @apply object-cover;
//   }
// }


// .marquee{
//   --gap: 1rem;
//   display: flex;
//   overflow: hidden;
//   user-select: none;
//   gap: var(--gap);
// }

.marqueeContent {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  gap: var(--gap);
  white-space: nowrap;
  will-change: transform;
  animation: scroll linear infinite forwards;
  margin-right: var(--gap);

  &.marqueeContentRev{
    white-space: nowrap;
    will-change: transform;
    animation: scrollBack linear infinite forwards;
  }

  &.marqueeVertical{
    will-change: transform;
    animation: scrollVertical linear infinite forwards;
    @apply flex-col;
    width: 50%;
    @apply min-w-[unset];

    &:last-of-type{
      will-change: transform;
      animation: scrollVerticalRev linear infinite forwards;
    }
    // margin-right: var(--gap);
  }

}

.duration5{
  animation-duration: 5s !important;
}.duration10{
  animation-duration: 10s !important;
}.duration15{
  animation-duration: 15s !important;
}.duration20{
  animation-duration: 20s !important;
}.duration25{
  animation-duration: 25s !important;
}.duration30{
  animation-duration: 30s !important;
}.duration35{
  animation-duration: 35s !important;
}.duration40{
  animation-duration: 40s !important;
}.duration45{
  animation-duration: 45s !important;
}.duration50{
  animation-duration: 50s !important;
}.duration55{
  animation-duration: 55s !important;
}.duration60{
  animation-duration: 60s !important;
}.duration65{
  animation-duration: 65s !important;
}.duration70{
  animation-duration: 70s !important;
}.duration75{
  animation-duration: 75s !important;
}.duration80{
  animation-duration: 80s !important;
}.duration85{
  animation-duration: 85s !important;
}.duration90{
  animation-duration: 90s !important;
}.duration95{
  animation-duration: 95s !important;
}.duration100{
  animation-duration: 100s !important;
}.duration105{
  animation-duration: 105s !important;
}.duration110{
  animation-duration: 110s !important;
}.duration115{
  animation-duration: 115s !important;
}.duration120{
  animation-duration: 120s !important;
}.duration125{
  animation-duration: 125s !important;
}.duration130{
  animation-duration: 130s !important;
}.duration135{
  animation-duration: 135s !important;
}.duration140{
  animation-duration: 140s !important;
}.duration145{
  animation-duration: 145s !important;
}.duration150{
  animation-duration: 150s !important;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

@keyframes scrollBack {
  from {
    transform: translateX(calc(-100% - var(--gap)));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes scrollVertical {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(-100% - var(--gap)));
  }
}

@keyframes scrollVerticalRev {
  from {
    transform: translateY(calc(-100% - var(--gap)));
  }
  to {
    transform: translateY(0);
  }
}

@keyframes marqueeLoop {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}


@keyframes marqueeLoopRev {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes scroll-abs {
  from {
    transform: translateX(calc(100% + var(--gap)));
  }
  to {
    transform: translateX(0);
  }
}
