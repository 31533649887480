.wrapper {
  @apply relative;

  &.light {
    @apply bg-white;
    @apply text-dark;

    .reviewBox {
      @apply bg-primary-fade;
    }
  }

  &.dark {
    @apply bg-black;
    @apply text-white;

    .container {
      @apply bg-dark;
    }

    h2,
    h3,
    h4 {
      @apply text-white;
    }

    div {
      @apply text-white;
      @apply border-white;
    }
  }

  &.tertiary {
    @apply bg-primary-fade;
    @apply text-dark;
  }
}

.container {
  @apply w-full;
  @apply px-6 xl:px-[5.3rem];
  @apply max-w-wrap;
  @apply py-[100px] mx-auto;
  @apply bg-white;
  @apply text-dark;

  h2 {
    @apply font-display;
    @apply font-black;
    @apply uppercase;
    @apply text-center;
    @apply mb-16;
    @apply max-w-[75%];
    @apply mt-0 mb-[60px] mx-auto;
    @apply text-3xl md:text-5xl;
    @apply leading-[0.9];
  }
}

.accordionWrapper {
  @apply flex flex-col gap-5;
  @apply lg:flex-row;
}

.desc {
  max-width: 85%;
}

.container h4,
.container h3 {
  @apply font-bold;
  @apply font-display;
  @apply leading-tight;
  @apply mt-5;
  @apply text-3xl;
}

.panel {
  flex: 1;
}

.accordion {
  flex: 2;
  @apply list-none;
}

.slim {
  @apply md:max-w-[60%];
  @apply m-auto;
}
