.wrapper {
  @apply border-0 border-b-2 border-black border-opacity-20;

  &:first-of-type {
    @apply border-t-0;
  }

  &.active {
    @apply mb-8;
  }
}

.tab {
  @apply cursor-pointer;
  @apply flex flex-row;
  @apply font-bold;
  @apply font-display;
  @apply gap-5;
  @apply items-center;
  @apply leading-tight;
  @apply py-8;
  @apply relative;
  @apply uppercase;
  @apply text-base;
  @apply lg:text-[22px];

  .arrow{
    @apply block;
    @apply w-[65px];
    @apply ml-auto;
    @apply pr-4;
  }
}

.control {
  font-size: 20px;
}

.content {
  @apply pb-4;
  @apply pr-0;
  @apply lg:px-[2.1rem];

  ul {
    list-style: initial;
    margin: initial;
  }
}

.content_wrapper {
  @apply overflow-hidden h-0;
  transition: height ease 0.2s;
}
