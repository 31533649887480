.exploreWrapper {
  margin-top: 70px;
  margin-bottom: 50px;
  @apply flex flex-col lg:flex-row;
  @apply gap-5;
}

.wrapper {
  @apply flex flex-col;
  flex: 2;

  a {
    @apply m-0 p-0 no-underline;
  }
}

.exploreInfo {
  flex: 1;
  @apply flex flex-col;
  @apply gap-5;

  h4 {
    @apply uppercase;
    @apply mt-0;
    @apply font-display;
    @apply font-bold;
    @apply text-3xl md:text-5xl;
    @apply leading-[1.2];

    text-shadow: 4px 4px 0 #181818, 4px 4px 0 #181818, 4px 4px 0 #181818,
      4px 4px 0 #181818;
  }

  .desc {
    max-width: 85%;
  }
}

.expProject {
  @apply flex flex-row items-center relative py-8;
  @apply md:justify-between;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.wrapper a:last-of-type .expProject {
  border-bottom: 0;
}

.expNumber,
.expClient,
.expServices,
.expArrow {
  @apply relative;
  z-index: 1;
}

.expNumber {
  width: 5%;
  font-size: 25px;
}

.expClient {
  @apply uppercase;
  font-size: 1.4rem;
  @apply font-display;
  @apply font-bold;
  line-height: 1.2;
  letter-spacing: 1px;
  width: 60%;
}

@media only screen and (min-width: 1024px) {
  .expClient {
    width: unset;
  }
}

.expServices {
  width: 40%;
}

.expArrow {
  width: 5%;
  @apply flex;
}

.expImage {
  @apply absolute;
  @apply z-0;
  left: 70%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(17deg);
  @apply opacity-0;
  transition: opacity 0.33s ease-in-out;
  pointer-events: none;
  @apply block;
  @apply md:block;
}

.expImage img {
  @apply hidden;
  /* height: 200px; */
}

@media only screen and (min-width: 1024px) {
  .expImage img {
    width: 250px;
    @apply block;
  }
}

.expProject:hover .expImage {
  @apply opacity-50;
  transition: opacity 0.33s ease-in-out;
}
