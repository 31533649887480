.wrapper {
  @apply w-full;
  @apply py-20;

  &.light {
    @apply bg-white;
    @apply text-dark;
  }

  &.dark {
    @apply bg-black;
    @apply text-white;

    .container {
      @apply bg-black;
    }

    h2 {
      @apply text-white;
    }

    .desc {
      @apply text-white;
    }
  }

  &.tertiary {
    @apply bg-primary-fade;
    @apply text-dark;
  }
}

.container {
  @apply bg-white bg-opacity-5;
  @apply max-w-wrap;
  @apply mx-auto;
  @apply py-12 px-0;
  @apply rounded-xl;
  @apply w-full;

  h2 {
    @apply block;
    @apply font-black;
    @apply font-display;
    @apply leading-[0.9];
    @apply text-xl md:text-4xl;
    @apply text-center;
    @apply m-0 mb-6 mx-auto;
  }

  .desc {
    @apply block;
    @apply text-center;
    @apply leading-normal;
    @apply md:max-w-[40%];
    @apply my-0 mb-10 mx-auto;
    @apply mt-10;
  }
}

.swiperContainer {
  padding-top: 50px !important;
}

.articlesWrapper {
  @apply grid items-center;
  grid-template-columns: var(--article-grid);
  gap: var(--article-grid-gap);
}

@media screen and (min-width: 560px) and (max-width: 960px) {
  .articlesWrapper div:nth-of-type(3) {
    @apply hidden;
  }
}
